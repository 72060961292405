module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KVPWBBDB8H"],"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Strona główna","crumbLabelUpdates":[{"pathname":"/nasi-podopieczni","crumbLabel":"Nasi podopieczni"},{"pathname":"/nasi-podopieczni/koty-do-adopcji","crumbLabel":"Koty do adopcji"},{"pathname":"/nasi-podopieczni/jeszcze-niegotowe","crumbLabel":"Jeszcze niegotowe"},{"pathname":"/nasi-podopieczni/w-swoich-domkach","crumbLabel":"W swoich domkach"},{"pathname":"/przed-adopcja","crumbLabel":"Przed adopcją"},{"pathname":"/przed-adopcja/warunki-adopcji","crumbLabel":"Warunki adopcji"},{"pathname":"/przed-adopcja/przygotowanie","crumbLabel":"Jak przygotować się do adopcji kota?"},{"pathname":"/zostan-tymczasem","crumbLabel":"Zostań tymczasem"},{"pathname":"/wesprzyj-nas","crumbLabel":"Wesprzyj nas"},{"pathname":"/kontakt","crumbLabel":"Kontakt"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/nasi-podopieczni/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b68db75e6ad79ca40960f432b7abc5f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
